<template>
  <el-cascader
    :style="{ width: width }"
    size="mini"
    :options="list"
    :show-all-levels="false"
    :props="cascProps"
    filterable
    clearable
    placeholder="请选择"
    v-model="selectValue"
  ></el-cascader>
</template>

<script>
import { getCityListAPI } from './api'
export default {
  name: 'SelectDept',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    width: {
      type: String,
      default: '200px'
    },
    city: {
      type: String,
      default: ''
    }
  },

  computed: {
    selectValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    selectValue: {
      handler(newV, oldV) {
        this.getCityName(this.list, this.selectValue)
      }
    }
  },

  data() {
    return {
      list: [],
      cascProps: {
        label: 'shortname',
        value: 'id',
        checkStrictly: false,
        expandTrigger: 'click',
        emitPath: false,
        multiple: false,
        leaf: 'leaf'
      }
    }
  },

  created() {
    this.getDeptTree()
  },

  methods: {
    async getDeptTree() {
      let res = await getCityListAPI()
      this.list = this.setLeaf(res)
    },

    setLeaf(list) {
      return list.map((item) => {
        if (item.level < 2 && item.children && item.children.length > 0) item.children = this.setLeaf(item.children)
        else {
          item.leaf = true
          delete item.children
        }
        return item
      })
    },
    getCityName(list, id) {
      list.forEach((item) => {
        if (item.id == id) {
          this.$emit('update:city', item.shortname)
        } else if (item.children && item.children.length > 0) {
          this.getCityName(item.children, id)
        }
      })
    }
  }
}
</script>

<style></style>
