<template>
  <div>
    <div ref="mapContainer" class="map-container"></div>
  </div>
</template>

<script>
import Marker_blue from '@/assets/icon/marker_blue.png'
export default {
  name: 'Map',
  props: {
    lat: {
      type: [String, Number],
      default: ''
    },
    lng: {
      type: [String, Number],
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    city: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      map: null,
      markerLayer: null,
      longitude: 116.397128,
      latitude: 39.916527,
      search: null,
      suggest: null
    }
  },

  mounted() {
    this.initMap()
  },
  computed: {},
  watch: {
    address: {
      handler(newV, oldV) {
        if (newV) {
          // if (this.lat || this.lng) return
          // this.searchByKeyword('全国', this.city + newV)
        }
      }
    }
    // city: {
    //   handler(newV, oldV) {
    //     if (newV) {
    //       // this.searchByKeyword('全国', newV)
    //       this.searchByKeyword('全国', newV)
    //     }
    //   }
    // }
    // lat: {
    //   handler(newV, oldV) {
    //     if (newV) {
    //       this.markerLayer.add({
    //         position: new TMap.LatLng(this.lat, this.lng),
    //         styleId: 'marker'
    //       })
    //       // this.map.setCenter(new TMap.LatLng(this.lat, this.lng))
    //     }
    //   }
    // }
  },
  methods: {
    initMap() {
      console.log('初始化', this.lat, this.lng)
      const center = new TMap.LatLng(this.lat || 39.984104, this.lng || 116.307503)
      //初始化地图
      this.map = new TMap.Map(this.$refs.mapContainer, {
        rotation: 20, //设置地图旋转角度
        pitch: 30, //设置俯仰角度（0~45）
        zoom: 15, //设置地图缩放级别
        center: center //设置地图中心点坐标
      })

      this.initMarker()
      this.markerLayer.add({
        position: new TMap.LatLng(this.lat, this.lng),
        styleId: 'marker'
      })
      this.map.on('click', (e) => {
        const { lat, lng } = e.latLng
        console.log(e)
        this.$emit('update:lat', lat)
        this.$emit('update:lng', lng)
        this.markerLayer.setMap(null)
        this.markerLayer = null
        this.initMarker()
        if (this.markerLayer) {
          this.markerLayer.add({
            position: e.latLng,
            styleId: 'marker'
          })
        }
      })
    },
    initMarker() {
      //初始化marker图层
      this.markerLayer = new TMap.MultiMarker({
        id: 'marker-layer',
        map: this.map,
        styles: {
          marker: new TMap.MarkerStyle({
            width: 27,
            height: 34,
            anchor: { x: 16, y: 32 },
            src: Marker_blue
          })
        }
      })
    },
    searchByKeyword(region = '', kw) {
      this.search = new TMap.service.Search({ pageSize: 10 })
      this.suggest = new TMap.service.Suggestion({
        pageSize: 10, // 返回结果每页条目数
        region: kw, // 限制城市范围
        regionFix: false // 搜索无结果时是否固定在当前城市
      })
      console.log(region, kw)
      this.suggest.getSuggestions({ keyword: kw, location: this.map.getCenter() }).then((res) => {
        console.log(res)
        const { location } = res.data[0]
        if (location) {
          // this.latitude = location.lat
          // this.longitude = location.lng
          this.map.setCenter(new TMap.LatLng(location.lat, location.lng))
          this.markerLayer.setMap(null)
          this.markerLayer = null
          this.initMarker()
          this.markerLayer.add({
            position: new TMap.LatLng(location.lat, location.lng),
            styleId: 'marker'
          })
          this.$emit('update:lat', location.lat)
          this.$emit('update:lng', location.lng)
        }
      })
    },
    setCenter() {
      console.log('设置中心')
      this.map.setCenter(new TMap.LatLng(this.lat, this.lng))
      // this.map.setCenter(new TMap.LatLng(39.908802, 116.397502))
      this.markerLayer.setMap(null)
      this.markerLayer = null
      this.initMarker()
      this.markerLayer.add({
        position: new TMap.LatLng(this.lat, this.lng),
        styleId: 'marker'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 300px;
}
</style>
