<template>
  <div class="wrapper">
    <div class="merchant-box">
      <el-image class="cover" style="width: 80px; height: 80px; border-radius: 4px; display: block" :src="detail.img" :preview-src-list="[detail.img]" fit="cover">
        <div slot="error" style="line-height: 80px; text-align: center; background: #f5f7fa">无</div>
      </el-image>
      <div class="merchant-info">
        <div class="name-box">
          <span class="name">{{ detail.store_title }}</span>
          <span>
            <el-rate :max="5" disabled allow-half :value="detail.star_num / 2"></el-rate>
          </span>
        </div>
        <div class="date-box">
          <span style="margin-right: 10px">{{ detail.work_day }}</span>
          <span>{{ detail.business_start }} ~ {{ detail.business_end }}</span>
        </div>
        <div class="addr">{{ detail.city + ' - ' + detail.address }}</div>
      </div>
    </div>
    <!-- 信息 -->
    <div class="user-box">
      <el-descriptions direction="vertical" :column="4" border>
        <el-descriptions-item label="是否过夜">
          <el-tag v-if="detail.is_stay_overnight == 0" size="small" type="danger">否</el-tag>
          <el-tag v-if="detail.is_stay_overnight == 1" size="small" type="primary">是</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="是否专人看护">
          <el-tag v-if="detail.is_orderlies == 0" size="small" type="danger">否</el-tag>
          <el-tag v-if="detail.is_orderlies == 1" size="small" type="primary">是</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="是否有监控">
          <el-tag v-if="detail.is_monitoring == 0" size="small" type="danger">否</el-tag>
          <el-tag v-if="detail.is_monitoring == 1" size="small" type="primary">是</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="小包价格元/件/天">{{ detail.packet_price }}</el-descriptions-item>
        <el-descriptions-item label="行李箱价格元/件/天">{{ detail.trunk_price }}</el-descriptions-item>
        <el-descriptions-item label="存柜类型">
          <el-tag v-if="detail.locker_type == 1" size="small" type="primary">人工</el-tag>
          <el-tag v-if="detail.locker_type == 2" size="small" type="primary">自动存储柜</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="营业时间"> {{ detail.work_day }}：{{ detail.business_start + '~' + detail.business_end }} </el-descriptions-item>
        <el-descriptions-item label="预定须知">{{ detail.booking_terms }}</el-descriptions-item>
        <el-descriptions-item label="店铺管理员">
          <div class="row">昵称：{{ detail.store_user.nickname }}</div>
          <div class="row">手机号：{{ detail.store_user.mobile }}</div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <!-- 休息日 -->
    <div class="size-box">
      <admin-title title="休息日" bottom="10px"></admin-title>
      <el-tag style="margin-right: 5px" size="small" type="primary" v-for="(item, i) in detail.close_day" :key="i">{{ item }}</el-tag>
    </div>
    <!-- 尺寸 -->
    <div class="size-box">
      <admin-title title="尺寸说明" bottom="10px"></admin-title>
      <div class="size-content" v-html="detail.size_intro"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>
<style lang="scss" scoped>
.wrapper {
  .merchant-box {
    display: flex;
    padding: 0 20px;
    margin-bottom: 20px;
    .merchant-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 20px;
      .name-box {
        display: flex;
        .name {
          margin-right: 20px;
          font-weight: 600;
        }
      }
      .date-box {
        display: flex;
        align-items: center;
      }
    }
  }
  // 用户信息
  .user-box {
    padding: 0 20px;
  }
  .size-box {
    padding: 0 20px;
    margin-top: 20px;
  }
}
</style>
