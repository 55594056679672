<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>

    <el-form class="search-form" ref="form" inline :model="searchData" label-width="">
      <el-form-item label="商户名称">
        <el-input style="width: 200px" v-model.trim="searchData.store_title" size="mini" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="商户ID">
        <el-input style="width: 200px" v-model.trim="searchData.id" size="mini" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="城市">
        <el-input style="width: 200px" v-model.trim="searchData.city" size="mini" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="联系方式">
        <el-input style="width: 200px" v-model.trim="searchData.contact" size="mini" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="账号状态">
        <el-select style="width: 200px" v-model="searchData.is_lock" placeholder="请选择" size="mini" clearable>
          <el-option label="正常" :value="0"> </el-option>
          <el-option label="锁定" :value="1"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="歇业时间">
        <el-date-picker
          style="width: 200px"
          v-model="searchData.close_day_ranges"
          size="mini"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="tabs">
      <div :class="['item', searchData.status === -1 ? 'active' : '']" @click="handleClickTab(-1)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <!-- <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">待审核</span>
        </el-badge>
      </div> -->
      <div :class="['item', searchData.status === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge>
          <span class="tab-name">营业中</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 10 ? 'active' : '']" @click="handleClickTab(10)">
        <el-badge>
          <span class="tab-name">暂停营业</span>
        </el-badge>
      </div>
    </div>
    <div class="btngroup">
      <el-button type="primary" size="mini" plain icon="el-icon-plus" @click="handleAdd">新增</el-button>
      <el-button icon="el-icon-download" type="primary" size="mini" plain @click="exportTable">导出</el-button>
    </div>
    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="id" label="商户ID" min-width="60"> </el-table-column>
      <el-table-column prop="store_title" label="商家名称" min-width="120"> </el-table-column>
      <el-table-column prop="store_balance" label="商户收益" min-width="220">
        <template slot-scope="{ row }">
          <div v-if="row">
            <div>可提现金额：{{ row.store_balance.balance }}</div>
            <div>冻结金额（收益）：{{ row.store_balance.frozen_income }}</div>
            <div>冻结金额（提现）：{{ row.store_balance.frozen_withdraw }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="contact" label="联系方式" min-width="120"> </el-table-column>
      <el-table-column prop="face" label="账号状态" min-width="120">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_lock == 0" type="primary" size="small">{{ row.is_lock_dsc }}</el-tag>
          <el-tag v-if="row.is_lock == 1" type="danger" size="small">{{ row.is_lock_dsc }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="store_rate" label="商户抽佣比例" min-width="120"> </el-table-column>
      <el-table-column prop="status_dsc" label="营业状态" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="warning" size="small">待审核</el-tag>
          <el-tag v-if="row.status == 10" type="danger" size="small">{{ row.status_dsc }}</el-tag>
          <el-tag v-if="row.status == 20" type="primary" size="small">{{ row.status_dsc }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="img" label="封面" min-width="100">
        <template slot-scope="{ row }">
          <el-image style="width: 60px; height: 60px; border-radius: 4px; display: block" :src="row.cover[0]" :preview-src-list="[...row.cover]" fit="cover">
            <div slot="error" style="line-height: 60px; text-align: center; background: #f5f7fa">无</div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="order_count" label="订单数" min-width="100"> </el-table-column>
      <el-table-column prop="earnings_sum" label="总收益" min-width="100"> </el-table-column>
      <el-table-column prop="business_start" label="营业时间" min-width="150">
        <template slot-scope="{ row }">
          <div>{{ row.business_start }} <span>~</span> {{ row.business_end }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="business_start" label="歇业时间" min-width="200">
        <template slot-scope="{ row }">
          <el-tag style="margin-right: 5px; margin-bottom: 5px" v-for="(item, i) in row.close_days" :key="i" type="primary" size="small">{{ item }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="入驻时间" min-width="150"> </el-table-column>

      <el-table-column prop="id" label="操作" width="150" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" :type="row.is_lock ? 'primary' : 'danger'" :underline="false" @click="handleLock(row)">{{
            row.is_lock ? '启用' : '禁用'
          }}</el-link>
          <el-link style="margin-right: 10px" type="info" :underline="false" @click="showDetail(row)">详情</el-link>
          <el-link style="margin-right: 10px" type="warning" :underline="false" @click="handleEdit(row)">编辑</el-link>
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleStatus(row)">营业状态</el-link>
          <el-link type="danger" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
    <!-- 详情弹窗 -->
    <el-drawer :visible.sync="show_detail" direction="rtl" size="55%">
      <Detail ref="detail" :detail="detail"></Detail>
    </el-drawer>
    <!-- 新增编辑弹窗 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_update" width="900px" @closed="closeDialog">
      <!-- 表单 -->
      <el-form class="form-data" ref="form" :model="formData" label-width="130px">
        <!-- 商户名称    是否专人看护 -->
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="商户名称：" :rules="rules">
              <el-input style="width: 250px" v-model="formData.store_title" size="mini" clearable placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="专人看护：">
              <el-radio-group v-model="formData.is_orderlies">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 联系方式   是否过夜 -->
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="联系方式：" :rules="rules">
              <el-input style="width: 250px" v-model="formData.contact" size="mini" clearable placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否过夜：">
              <el-radio-group v-model="formData.is_stay_overnight">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 工作日 -->
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="工作日：" :rules="rules">
              <el-input style="width: 250px" v-model="formData.work_day" size="mini" clearable placeholder="例如：周一至周五"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否有监控：">
              <el-radio-group v-model="formData.is_monitoring">
                <el-radio :label="1">是 </el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 营业开始时间   是否有监控 -->
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="营业开始时间：" :rules="rules">
              <!-- <el-time-select
                style="width: 250px"
                v-model="formData.business_start"
                :picker-options="{
                  start: '00:00',
                  step: '00:15',
                  end: '24:00'
                }"
                placeholder="开始时间"
                size="mini"
                @change="changeStartTime"
              >
              </el-time-select> -->
              <el-input style="width: 250px" v-model="formData.business_start" size="mini" clearable placeholder="营业开始时间"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="存柜类型：">
              <el-radio-group v-model="formData.locker_type">
                <el-radio :label="1">人工</el-radio>
                <el-radio :label="2">自动存储柜</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 营业结束时间 -->
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="营业结束时间：" :rules="rules">
              <!-- <el-time-select
                style="width: 250px"
                v-model="formData.business_end"
                :picker-options="{
                  start: '00:00',
                  step: '00:15',
                  end: '24:00',
                  minTime: formData.business_start
                }"
                placeholder="结束时间"
                size="mini"
              >
              </el-time-select> -->
              <el-input style="width: 250px" v-model="formData.business_end" size="mini" clearable placeholder="营业结束时间"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="城市：">
              <!-- <el-input style="width: 250px" v-model="formData.city" size="mini" clearable placeholder="请输入" @change="changeCity"></el-input> -->
              <City v-model="formData.city_id" :city.sync="formData.city" width="200px"></City>
            </el-form-item>
          </el-col>
        </el-row>
        <!--城市  地址 -->
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="小包价格：" :rules="rules">
              <el-input-number
                style="width: 190px"
                v-model="formData.packet_price"
                placeholder="小包价格元/件/天"
                :precision="2"
                :step="0.01"
                :min="null"
                size="mini"
              ></el-input-number>
              <span style="margin-left: 10px; color: #909399">元/件/天</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地址：">
              <el-input style="width: 150px" disabled :value="formData.address" size="mini" placeholder="空"></el-input>
              <el-link style="margin-left: 10px" :underline="false" type="primary" @click="goEditAddress">去设置</el-link>
            </el-form-item>
          </el-col>
        </el-row>
        <!--行李箱价格元/件/天   小包价格元/件/天  -->
        <el-row style="margin-bottom: 10px" :gutter="10">
          <el-col :span="12">
            <el-form-item label="行李箱价格：" :rules="rules">
              <el-input-number
                style="width: 190px"
                v-model="formData.trunk_price"
                placeholder="行李箱价格元/件/天"
                :precision="2"
                :step="0.01"
                :min="null"
                size="mini"
              ></el-input-number>
              <span style="margin-left: 10px; color: #909399">元/件/天</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="尺寸说明：">
              <el-link :underline="false" type="primary" @click="goEditSize">去编辑</el-link>
            </el-form-item>
          </el-col>
        </el-row>
        <!--照片  预定须知  -->
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="商户抽佣比例：">
              <el-input-number style="width: 190px" v-model="formData.store_rate" :step="0.01" :precision="2" size="mini" :min="0" :max="100"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预定须知：">
              <el-input type="textarea" v-model="formData.booking_terms" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px" :gutter="10">
          <el-col :span="24">
            <el-form-item label="照片：" class="cover">
              <UploadImgs :background_images.sync="formData.cover" append></UploadImgs>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 提交 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="show_update = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submit" size="mini">提 交</el-button>
      </span>
      <!-- 地址 -->
      <el-dialog width="700px" title="地址" :visible.sync="show_address" @open="openMap" append-to-body>
        <el-input style="width: 300px; margin-bottom: 20px" v-model="formData.address" placeholder="例如：南京路100号" size="mini" @input="changeAddress"></el-input>
        <div class="map">
          <Map ref="map" :lng.sync="formData.longitude" :lat.sync="formData.latitude" :city="formData.city" :address="formData.address"></Map>
        </div>
      </el-dialog>
      <!-- 尺寸说明 -->
      <el-dialog width="700px" title="尺寸说明" :visible.sync="show_size" append-to-body>
        <Editor ref="editor" :showBtn="false" :content.sync="formData.size_intro"></Editor>
      </el-dialog>
    </el-dialog>
    <!-- 修改状态 -->
    <el-dialog title="修改状态" :visible.sync="show_status" width="width">
      <el-form ref="status" :model="statusData" label-width="120px">
        <el-form-item label="状态">
          <el-radio-group v-model="statusData.status">
            <el-radio :label="0">待审核</el-radio>
            <el-radio :label="20">正常营业</el-radio>
            <el-radio :label="10">暂停营业</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_status = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="confirmSatatus" size="mini">确 定</el-button>
      </div>
    </el-dialog>

    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { getListAPI, getDetailAPI, addAPI, editAPI, delAPI, changeStatusAPI, changeLockAPI, DownloadAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import UploadImgs from '@/components/qiniu-uploader/uploader-imgs.vue'
import Detail from './detail.vue'
import Map from '@/components/map/map.vue'
import Editor from '@/components/editor/index.vue'
import City from './select-area.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { Detail, UploadImgs, Map, Editor, City },
  data() {
    return {
      city: '',
      rules: [{ required: true, message: '该项不能为空' }],
      show_update: false,
      show_detail: false,
      show_address: false,
      show_status: false,
      show_size: false,
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        id: '',
        status: -1,
        city: '',
        is_lock: '',
        contact: '',
        close_day_ranges: []
      },
      total: 0,
      detail: {},
      formData: {
        store_title: '',
        img: '',
        is_stay_overnight: 1, //是否过夜0否1是
        is_orderlies: 1, //是否专人看护0否1是
        is_monitoring: 1, //是否有监控0否1是
        work_day: '',
        latitude: '',
        longitude: '',
        city: '',
        city_id: '',
        address: '',
        trunk_price: '', //行李箱价格元/件/天
        packet_price: '', //小包价格元/件/天
        locker_type: 1, //存柜类型 1人工 2自动存储柜
        business_start: '',
        business_end: '',
        booking_terms: '', //预定须知
        contact: '',
        size_intro: '',
        store_rate: '',
        cover: []
      },
      statusData: {
        status: 20,
        id: ''
      }
    }
  },
  watch: {
    'formData.city': {
      handler() {
        // this.formData.address = ''
        // this.formData.latitude = ''
        // this.formData.longitude = ''
      }
    }
  },
  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    handleLock({ id, is_lock }) {
      let title = is_lock ? '启用' : '禁用'
      const data = { id, is_lock: is_lock ? 0 : 1 }
      this.$confirm(`${title}该账号, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          changeLockAPI(data).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    changeStartTime() {
      this.formData.business_end = ''
    },
    async getList() {
      let params = { ...this.searchData }
      if (this.searchData.status == -1) {
        params.status = ''
      }
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total
    },
    exportTable() {
      const { id, status, city, is_lock, contact, close_day_ranges } = this.searchData
      let params = { id, city, is_lock, contact, close_day_ranges }
      if (status > -1) {
        params.status = status
      }
      DownloadAPI(params)
    },
    closeDialog() {
      this.formData.cover = []
    },
    // 保存新增编辑
    submit() {
      if (!this.formData.store_title) return this.$message.warning('商户名称不能为空')
      if (!this.formData.contact) return this.$message.warning('联系方式不能为空')
      let data = { ...this.formData }
      data.cover = data.cover.map((item) => item.url)

      // return
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(data).then((res) => {
              this.$message.success('编辑成功')
              this.getList()
              this.show_update = false
            })
          } else {
            addAPI(data).then((res) => {
              this.$message.success('新增成功')
              this.getList()
              this.show_update = false
            })
          }
        }
      })
    },
    // 新增
    handleAdd() {
      this.show_update = true
      this.formData = {
        store_title: '',
        img: '',
        is_stay_overnight: 1, //是否过夜0否1是
        is_orderlies: 1, //是否专人看护0否1是
        is_monitoring: 1, //是否有监控0否1是
        work_day: '',
        latitude: '',
        longitude: '',
        city: '',
        city_id: '',
        address: '',
        trunk_price: '', //行李箱价格元/件/天
        packet_price: '', //小包价格元/件/天
        locker_type: 1, //存柜类型 1人工 2自动存储柜
        business_start: '',
        business_end: '',
        booking_terms: '', //预定须知
        contact: '',
        size_intro: '',
        store_rate: ''
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    // 编辑
    async handleEdit({ id }) {
      this.show_update = true
      const res = await getDetailAPI(id)
      this.formData = { ...res }
      if (this.formData.cover) {
        this.formData.cover = this.formData.cover.map((item) => {
          return {
            name: '',
            url: item
          }
        })
      } else {
        this.formData.cover = []
      }

      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    // 删除
    handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 状态
    handleStatus({ id, status }) {
      this.statusData = {
        id,
        status
      }
      this.show_status = true
    },
    confirmSatatus() {
      changeStatusAPI(this.statusData).then(() => {
        this.$message.success('状态修改成功')
        this.getList()
        this.show_status = false
      })
    },
    // 详情按钮
    async showDetail({ id }) {
      this.detail = await getDetailAPI(id)

      this.show_detail = true
    },
    // 点击tab
    handleClickTab(i) {
      if (this.searchData.status == i) return
      this.searchData.status = i
      this.getList()
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    // 编辑地址
    goEditAddress() {
      if (!this.formData.city) return this.$message.warning('请先输入城市名')
      this.show_address = true

      this.$nextTick(() => {
        // if (this.formData.id) {
        //   this.$refs.map.initMap()
        //   this.$refs.map.setCenter()
        //   return
        // }
        // this.$refs.map.searchByKeyword('全国', this.formData.city + this.formData.address)
      })
    },
    openMap() {
      this.$nextTick(() => {
        if (this.formData.id) {
          // this.$refs.map.initMap()
          this.$refs.map.setCenter()
          return
        }
        this.$refs.map.searchByKeyword('全国', this.formData.city + this.formData.address)
      })
    },
    changeAddress() {
      this.$refs.map.searchByKeyword('全国', this.formData.city + this.formData.address)
    },
    goEditSize() {
      this.show_size = true
      this.$nextTick(() => {
        this.$refs.editor.init()
      })
    },
    changeCity() {
      this.formData.address = ''
      this.formData.latitude = ''
      this.formData.longitude = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-divider {
    margin-top: 0;
  }
  .search-form {
    margin-bottom: 10px;
  }
  .btngroup {
    margin-bottom: 10px;
  }
  .form-data {
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
  // 用户信息
  .user {
    display: flex;
    align-items: center;
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      .nickname {
        display: flex;
        span {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.cover /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }
  .avatar {
    width: 108px;
    height: 108px;
    display: block;
  }
}
</style>
